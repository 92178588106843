<template>
  <div class="nhsfz">
    <div class="banner">
      <img src="static/lvya/nhsfz/banner.png" alt="" style="width:100%">
    </div>
    <Cont1></Cont1>
    <Cont2></Cont2>
    <Cont3></Cont3>
    <Cont4></Cont4>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
import Cont1 from './coms/cont1'
import Cont2 from './coms/cont2'
import Cont3 from './coms/cont3'
import Cont4 from './coms/cont4'
export default {
  components: { Cont1, Cont2, Cont3, Cont4 },
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
          boxClass: 'wow',
          animateClass: 'animate__animated',
          offset: '0',
          mobile: true,
          live: true
      })
      wow.init()
    })
  },
}
</script>
