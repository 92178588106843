import { render, staticRenderFns } from "./cont1.vue?vue&type=template&id=01df3a54&scoped=true"
var script = {}
import style0 from "./cont1.vue?vue&type=style&index=0&id=01df3a54&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01df3a54",
  null
  
)

export default component.exports